import AnalyticsIcon from "@mui/icons-material/Analytics";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AutoModeOutlinedIcon from "@mui/icons-material/AutoModeOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SportsKabaddiTwoToneIcon from "@mui/icons-material/SportsKabaddiTwoTone";
import StorefrontsIcon from "@mui/icons-material/Storefront";
import { styled } from "@mui/material";

export function featureIcons() {
  const EdgeIcon = styled(SportsKabaddiTwoToneIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.edge};
`,
  );

  const DealIcon = styled(LocalOfferOutlinedIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.deal};
`,
  );

  const CopilotIcon = styled(AutoModeOutlinedIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.deal};
`,
  );

  const MiaIcon = styled(ChatOutlinedIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.deal};
`,
  );

  const TrainingIcon = styled(ModelTrainingOutlinedIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.deal};
`,
  );

  const ViewIcon = styled(AnalyticsIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.view};
`,
  );

  const InsightIcon = styled(LibraryBooksIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );

  const StorefrontIcon = styled(StorefrontsIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );
  const StyledSettingsIcon = styled(SettingsIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );
  const RangeReviewIcon = styled(AnalyticsOutlinedIcon)(
    ({ theme }) => `
        color: ${theme.colors.features.insights};
`,
  );
  return {
    ViewIcon,
    EdgeIcon,
    DealIcon,
    MiaIcon,
    CopilotIcon,
    InsightIcon,
    TrainingIcon,
    StorefrontIcon,
    StyledSettingsIcon,
    RangeReviewIcon,
  };
}
