import { styled } from "@mui/material";
import axios from "axios";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "nprogress/nprogress.css";

import App from "./App";
import { AuthProvider } from "./contexts/Auth0Context";
import { SidebarProvider } from "./contexts/SidebarContext";
import ScrollTop from "./hooks/useScrollTop";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import fetchCSRFToken from "./utils/fetchCSRFToken";

// Default headers
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.withCredentials = true; // configure Axios to include credentials or cookies with cross - origin requests

// Axios interceptor to automatically add the CSRF token to requests
axios.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    if (
      newConfig.method === "post" ||
      newConfig.method === "put" ||
      newConfig.method === "patch" ||
      newConfig.method === "delete"
    ) {
      const csrfToken = await fetchCSRFToken();
      newConfig.headers["X-CSRFToken"] = csrfToken;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: `${theme.palette.success.light}`,
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: `${theme.palette.error.main}`,
    },
  }),
);

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <AuthProvider>
            <SnackbarProvider
              maxSnack={1}
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
              }}
            >
              <App />
            </SnackbarProvider>
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
