import { Slide } from "@mui/material";
import { useSnackbar } from "notistack";

export const useSnackbarService = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Slide,
      autoHideDuration: 3000,
    });
  };

  const showErrorSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Slide,
      autoHideDuration: 3000,
    });
  };

  return {
    showSuccessSnackbar,
    showErrorSnackbar,
  };
};
